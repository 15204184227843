import React, { useEffect } from 'react'
import AboutImage from '../images/gallery/table2.jpg';
import '../App.css';
import Aos from "aos";
import "aos/dist/aos.css";


const About = () => {

  useEffect(() => {
    Aos.init({ 
      offset: 200,
      duration: 2000,
      easing: 'ease-in-sine',
      delay: 100,
     });
  }, []);

  return (
    <div className='about-section'>
      <div className="container">
        <div className="my-5 py-3 row">
          <div className="col-md-6" data-aos="fade-up">
            <img src={AboutImage} alt="about-img" className='img-fluid' />
          </div>
          <div className="col-md-6">
            <h4 className='about-title'>About</h4>
            <div className='about-text'>
              <p>
                Global food & Drink Co is a business that has been created by food lovers for food lovers. From our unique branding to vibrant decor we strive to ensure the entire experience is the best every time.
              </p>
              <p>
                Burgers, wraps, peri peri chicken, dedicated vegetarian options and lots more may form the basis of our menu, but we are a lot more than just serving food. We truly differ from others in the way we go about our business.</p>


              <p>From our unique recipes which are the talk of the town, food grilled to taste, to the best customer service Global Food & Drink Co is all about providing the full experience.</p>
              <p>In a nutshell our aim is to become famous as a company that serves delicious, high-quality and value for money</p>

            </div>
          </div>
        </div>


      </div>
    </div>
  )
}

export default About