
import ImageOne from '../images/bg/bg-2.jpg';
import MSNameLogo from '../images/MS-Name-logo.png'
// import ImageTwo from '../images/header2.jpg';

export const SliderData = [
    {
        title: '"We will Bring Care to your life"',
        tagLine: 'Partner with Mohammadia Shawarmas',
        path: '/about',
        label: 'About Us',
        imageMS: MSNameLogo,
        image: ImageOne,
        alt: 'House'
    },
    // {
    //     title: 'Luxury villa in Bali, Indonesia',
    //     price: '$4,280,000',
    //     path: '/homes',
    //     label: 'View Home',
    //     image: ImageTwo,
    //     alt: 'House'
    // }
];