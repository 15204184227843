import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import GFDC from '../images/logo.png';




const FloatingWhatsapp = () => {
  return (
    <div>
        <FloatingWhatsApp phoneNumber='+91 8885300848' accountName='Gobal Food & Drink Co' avatar={GFDC} />
    </div>
  )
}

export default FloatingWhatsapp