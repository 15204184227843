import React, { useEffect } from 'react'
import styled from 'styled-components';
import { Mocktails, CrispyChicken, Salad, FrenchFries, Pizza, Sandwiches, Burger, RegularShawarma, SpecialShwawarma, DonarKababsShawarma, KababShwawarma, SpecialChickenBBQ, SpecialWrap, Extras, SpecialVeg} from '../data/ItemsData';
import Aos from "aos";
import "aos/dist/aos.css";

// import '../App.css';

const Menu = () => {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);


  return (
    <>
      <div className="menu-section">
        <div className="container">
          <h2 className='menu-title'>GFDC MENU</h2>

          {/* Menu Burger Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Burgers</h3>
            <div className="row">
              {Burger.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>

                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Burger Section Ends */}


          {/* Menu Sandwiches Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Sandwiches</h3>
            <div className="row">
              {Sandwiches.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>

                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Sandwiches Section Ends */}

          {/* Menu Pizza Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Pizza</h3>
            <div className="row">
              {Pizza.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-12 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>

                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>

                          <ItemPrice> {item.price7} /-</ItemPrice>
                          <ItemTitle>{item.product7}  </ItemTitle>

                          <ItemPrice> {item.price8} /-</ItemPrice>
                          <ItemTitle>{item.product8}  </ItemTitle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Pizza Section Ends */}

          {/* Menu Fries Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>French Fries</h3>
            <div className="row">
              {FrenchFries.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-12">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-4 col-12 d-flex flex-row align-items-center">
                      <div className='p-3'>       
                          <ItemPrice> {item.price11} /-</ItemPrice>                 
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>

                          <ItemPrice> {item.price12} /-</ItemPrice>
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price13} /-</ItemPrice>
                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price14} /-</ItemPrice>
                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Fries Section Ends */}

          {/* Menu Mocktails Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Mocktails</h3>
            <div className="row">
              {Mocktails.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up" key={index}>
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>

                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Mocktails Section Ends */}


          {/* Menu Salads Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Salads</h3>
            <div className="row">
              {Salad.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-xl-4">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-xl-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>

                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>   
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Salads Section Ends */}

          {/* Menu Crispy Chicken Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Crispy Chicken</h3>
            <div className="row">
              {CrispyChicken.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>

                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>   
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Crispy Chicken Section Ends */}


          <h2 className='menu-title'>MOHAMMEDIA SHAWARMAS MENU</h2>
         
         
          {/* Menu RegularShawarma Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>RegularShawarma</h3>
            <div className="row">
              {RegularShawarma.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu RegularShawarma Section Ends */}


          {/* Menu SpecialShwawarma Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>SpecialShwawarma</h3>
            <div className="row">
              {SpecialShwawarma.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu SpecialShwawarma Section Ends */}
          

          {/* Menu DonarKababsShawarma Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>DonarKababsShawarma</h3>
            <div className="row">
              {DonarKababsShawarma.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu DonarKababsShawarma Section Ends */}
          
          {/* Menu KababShwawarma Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>KababShwawarma</h3>
            <div className="row">
              {KababShwawarma.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu KababShwawarma Section Ends */}
        
          {/* Menu SpecialChickenBBQ Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>SpecialChickenBBQ</h3>
            <div className="row">
              {SpecialChickenBBQ.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price11} /-</ItemPrice>                          
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price12} /-</ItemPrice>
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price13} /-</ItemPrice>
                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price14} /-</ItemPrice>
                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price15} /-</ItemPrice>
                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>
  
                          <ItemPrice> {item.price16} /-</ItemPrice>
                          <ItemPrice> {item.price6} /-</ItemPrice>
                          <ItemTitle>{item.product6}  </ItemTitle>

                          <ItemPrice> {item.price17} /-</ItemPrice>
                          <ItemPrice> {item.price7} /-</ItemPrice>
                          <ItemTitle>{item.product7}  </ItemTitle>

                          <ItemPrice> {item.price18} /-</ItemPrice>
                          <ItemPrice> {item.price8} /-</ItemPrice>
                          <ItemTitle>{item.product8}  </ItemTitle>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu SpecialChickenBBQ Section Ends */}


          {/* Menu SpecialWrap Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>SpecialWrap</h3>
            <div className="row">
              {SpecialWrap.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                          <ItemPrice> {item.price5} /-</ItemPrice>
                          <ItemTitle>{item.product5}  </ItemTitle>
  
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu SpecialWrap Section Ends */}
          Extras
          {/* Menu Extras Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>Extras</h3>
            <div className="row">
              {Extras.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu Extras Section Ends */}
          
          {/* Menu SpecialVeg Section Starts*/}
          <div className="sub-menu">
            <h3 className='menu-sub-title'>SpecialVeg</h3>
            <div className="row">
              {SpecialVeg.map((item, index) => (
                <div className="col-md-6" data-aos="fade-up">
                  <div className='card-item'>
                    <div className="row">
                      <div className="col-md-4 col-5">
                        {/* <img className='img-fluid' src={item.image} alt="Item" /> */}
                      </div>
                      <div className="col-md-8 col-7 d-flex flex-row align-items-center">
                      <div className='p-3'>                        
                          <ItemPrice> {item.price1} /-</ItemPrice>                          
                          <ItemTitle>{item.product1}  </ItemTitle>
                          
                          <ItemPrice> {item.price2} /-</ItemPrice>
                          <ItemTitle>{item.product2}  </ItemTitle>

                          <ItemPrice> {item.price3} /-</ItemPrice>
                          <ItemTitle>{item.product3}  </ItemTitle>

                          <ItemPrice> {item.price4} /-</ItemPrice>
                          <ItemTitle>{item.product4}  </ItemTitle>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Menu SpecialVeg Section Ends */}
        </div>
      </div>
    </>
  )
}

export default Menu;



const ItemTitle = styled.h4`
padding: 5px;
  font-size: 0.9rem;
  text-align: left;
  font-weight: bold;
  font-family: 'MigraBold';
  color: #fff;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ItemPrice = styled.h4`
  padding-right: 15px;
  padding-top: 5px;
  font-size: 0.8rem;
  float: right;
  font-weight: bold;
  color: #fff;
`;

// const ItemText = styled.p`  
//   padding: 5px;
//   font-size: 12px;
//   text-align: left;
//   color: #d1d1d1;

//   // overflow: hidden;
//   //  text-overflow: ellipsis;
//   //  display: -webkit-box;
//   //  -webkit-line-clamp: 2; /* number of lines to show */
//   //          line-clamp: 2;
//   //  -webkit-box-orient: vertical;
// `;

// const UnorderedList = styled.ul`
//     color: #fff;
//     text-align: left;

//     &:hover{
//       color: #f68713;
//     }
// `;