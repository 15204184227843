
import VBRUGERSINGLE from '../images/items/VBURGERSINGLE.jpg'


export const Burger = [
    {
        
        image: VBRUGERSINGLE,
        product1: 'Chicken Burger',
        product2: 'Chicken 65 Burger',
        product3: 'Zinger Chicken Burger',
        product4: 'BBQ Chicken Burger',
        product5: 'Veg Burger',
        product6: 'Paneer Burger',

        price1: '80',
        price2: '90',
        price3: '90',
        price4: '90',
        price5: '70',
        price6: '80',

        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const Sandwiches = [
    {
        
        image: VBRUGERSINGLE,
        product1: 'Chicken Grilled Sandwich',
        product2: 'Chicken 65 Grilled Sandwich',
        product3: 'BBQ Chicken Grilled Sandwich',
        product4: 'Veg Grilled Sandwich',
        product5: 'Paneer Grilled Sandwich',
        product6: 'Sweet Corn Grilled Sandwich',

        price1: '80',
        price2: '99',
        price3: '99',
        price4: '60',
        price5: '79',
        price6: '79',

        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const Pizza = [
    {
        
        image: VBRUGERSINGLE,
        product1: 'Chicken Pizza',
        product2: 'Chicken 65 Pizza',
        product3: 'BBQ 65 Pizza',
        product4: 'Peri Peri Chicken Pizza',
        product5: 'Veg Pizza',
        product6: 'Paneer Pizza',
        product7: 'Sweet Corn Pizza',
        product8: 'Plain Cheez Pizza',

        price1: '179',
        price2: '199',
        price3: '199',
        price4: '189',
        price5: '149',
        price6: '179',
        price7: '159',
        price8: '149',

        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const FrenchFries = [
    {
        
        image: VBRUGERSINGLE,
        product1: 'French Fries',
        product2: 'Cheesy Fries',
        product3: 'Peri Peri Fries',
        product4: 'Schezwan Fries',

        price1: '90',
        price2: '110',
        price3: '110',
        price4: '110',

        price11: '130',
        price12: '140',
        price13: '140',
        price14: '140',

        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },
];

export const Mocktails = [
    {
        
        image: VBRUGERSINGLE,
        product1: 'Virgin Mojito Mocktail',
        product2: 'Blue Mint Moctail',
        product3: 'Green Apple Mocktail',
        product4: 'Strawberry Mocktail',
        product5: 'Water Melon Mocktail',
        product6: 'Kiwi Mocktail',

        price1: '59',
        price2: '59',
        price3: '59',
        price4: '59',
        price5: '59',
        price6: '59',
      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const Salad= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'Chicken Salad',
        product2: 'Chicken 65 Salad',
        product3: 'Chicken BBQ Salad',
        product4: 'Egg Salad',
        product5: 'Paneer Salad',
        product6: 'Veg  Salad',
        product7: 'Shawarma Salad',

        price1: '129',
        price2: '139',
        price3: '139',
        price4: '119',
        price5: '139',
        price6: '99',
        price7: '179',

      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const CrispyChicken= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'Crispy Chicken (1 pcs)',
        product2: 'Crispy Chicken (2 pcs)',
        product3: 'Crispy Chicken (4 pcs)',
        product4: 'Crispy Popcorn (M)',
        product5: 'Chicken Nuggets  (6Pcs)',
        product6: 'Memos Fried  (6Pcs)',

        price1: '59',
        price2: '119',
        price3: '279',
        price4: '149',
        price5: '89',
        price6: '119',
      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];


export const  RegularShawarma= [
    {
        
        image: VBRUGERSINGLE,
        product1: ' Regular Shwarma',
        product2: 'KHUBUS SHAWARMA',
        product3: 'RUMALI SHAWARMA',

        price1: '120',
        price2: '120',
        price3: '120',

      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const  SpecialShwawarma= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'SAMOLI SHAWARMA',
        product2: 'KHUBUS SHAWARMA',
        product3: 'RUMALI SHAWARMA',

        price1: '140',
        price2: '140',
        price3: '140',

      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const  DonarKababsShawarma= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'SAMOLI SHAWARMA',
        product2: 'KHUBUS SHAWARMA',
        product3: 'RUMALI SHAWARMA',

        price1: '150',
        price2: '150',
        price3: '150',

      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const  KababShwawarma= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'BARBEQUE SHAWARMA',
        product2: 'MALAI KABAB SHAWARMA',
        product3: 'HARYALI KABAB SHAWARMA',
        product4: 'RESHMI KABAB SHAWARM',
        product5: 'SHEEK KABAB SHAWARMA',


        price1: '160',
        price2: '160',
        price3: '160',
        price4: '160',
        price5: '160',

      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];


export const  SpecialChickenBBQ = [
    {
        
        image: VBRUGERSINGLE,
        product1: 'CHICKEN BARBEQUE (5PCS)',
        product2: 'MALAI KABAB (5PCS)',
        product3: 'HARYALI KABAB (5PCS)',
        product4: 'RESHMI KABAB (5PCS)',
        product5: 'SHEEK KABAB (1PCS)',
        product6: 'CHICKEN WINGS (5PCS)',
        product7: 'CHICKEN LOLIPOP (5PCS)',
        product8: 'TANGADI KABAB (1PCS)',

        price1: '90',
        price2: '100',
        price3: '90',
        price4: '90',
        price5: '90',
        price6: '90',
        price7: '100',
        price8: '60',


        price11: '170',
        price12: '190',
        price13: '170',
        price14: '170',
        price15: '170',
        price16: '170',
        price17: '190',
        price18: '300',


      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];


export const  SpecialWrap= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'EGG SABBA',
        product2: 'EGG SHAWARMA',
        product3: 'EGG CHICKEN SABBA',
        product4: 'CHICKEN SABBA',
        product5: 'CHICKEN CHEESE SHAWARMA',

        price1: '140',
        price2: '160',
        price3: '160',
        price4: '160',
        price5: '160',


      
        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];


export const  Extras= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'WATER BOTTLE (big)',
        product2: 'WATER BOTTLE(Small)',
        product3: 'MAYONNAISE BOWL',
        product4: 'FALAFIL',

        price1: '20',
        price2: '10',
        price3: '20',
        price4: '20',

        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];

export const SpecialVeg= [
    {
        
        image: VBRUGERSINGLE,
        product1: 'FALAFIL SABBA VEG WRAP',
        product2: 'FRENCH FRIES SABBA VEG',
        product3: 'CHEESE SABBA VEG WRAP',
        product4: 'CHEESE FRENCH FRIES SABBA VEG WRAP',
        product5: 'PANEER SHAWARMA',
        product6: 'CHEESE PANEER SHAWARMA',
        product7: 'MINT PANEER SHAWARMA',
        product8: 'BBQ PANEER  SHAWARMA',


        price1: '120',
        price2: '130',
        price3: '130',
        price4: '150',
        price5: '150',
        price6: '160',
        price7: '160',
        price8: '160',


        alt: 'INDIAN PANEER WRAP',
        link: 'View More'
    },

];


