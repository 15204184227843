import React from 'react';
import styled from 'styled-components';
import '../App.css';


const MapDiv = styled.div`
    padding-top: 1rem;
    padding-bottom: 3rem;
`;
const ContactInfo = styled.div`
    a {
        font-size: 1rem;
        color: #b2b6b8;
        text-decoration: none;
    }
`;

const UList = styled.ul`
        li{
            font-size: 1rem;
        color: #b2b6b8;
        text-decoration: none;
        list-style-type: none
        }
`;


const Location = () => {
    return (
        <div className='location-section'>
            <ContactInfo>
                <div className="container">
                <h4 className="mt-5 pt-5 gallery-title">Contact</h4>
                    <div className="row justify-content-md-center">
                        <div className="col-md-6 col-sm-12 pb-4">
                            <p className='footer-sub-heads'>Call Us..</p>
                            <a href='tel:+44 736 595 5005'>+91 8885300848</a>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <p className='footer-sub-heads'>Mail Us..</p>
                            <a href='mailto:info@mrvgrill.co.uk'>info@globlefoodanddrinkco.com</a><br />
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <p className='footer-sub-heads pb-3'>Visit Us..</p>
                            <UList>
                                <li>Monday : 11:00 - 23:00</li>
                                <li>Tuesday : 11:00 - 23:00</li>
                                <li>Wednesday : 11:00 - 23:00</li>
                                <li>Thursday : 11:00 - 23:00</li>
                                <li>Friday : 11:00 - 23:00</li>
                                <li>Saturday : 11:00 - 23:00</li>
                                <li>Sunday : 11:00 - 23:00</li>
                            </UList>
                            <br />
                        </div>                        
                    </div>
                </div>
            </ContactInfo>
            <MapDiv>
                <div className="container">
                    <h2 className='footer-sub-heads pb-3'>Find us..</h2>
                    <MapFrame src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3807.267704803626!2d78.4437666!3d17.3989358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb975c783fe603%3A0x4fff1619dbe7a34b!2sMohammedia%20Shawarma!5e0!3m2!1sen!2suk!4v1723325928514!5m2!1sen!2suk"
                        width="100%"
                        height="450" 
                        title='map-location'
                        style={{ border: '0' }}
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade">
                    </MapFrame>
                </div>
            </MapDiv>
        </div>
    )
}

export default Location;


const MapFrame = styled.iframe`
    filter: grayscale(50%) invert(100%);
`;